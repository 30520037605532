import React from "react";
import ThoughtsFeed from "../../components/ThoughtsFeed";
import LayoutArchive from "../../components/LayoutArchive";

export const BlogIndexPage = () => {
  return (
    <LayoutArchive>
      <ThoughtsFeed />
    </LayoutArchive>
  );
};
export default BlogIndexPage;
