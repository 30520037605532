import React, { Component } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import { mainBg } from "../styles";
import { Context } from "../state";

const Background = styled.div`
  background: ${mainBg};
  padding: 48px 0;
`;

class LayoutArchive extends Component {
  static contextType = Context;

  componentDidMount() {
    const [, dispatch] = this.context;
    dispatch({ type: "PAGE_TYPE", payload: "archive" });
  }

  render() {
    return (
      <Layout>
        <Background>
          <div className="small-container">{this.props.children}</div>
        </Background>
      </Layout>
    );
  }
}

export default LayoutArchive;
